<template>
  <section id="reservas" class="booking reservas">
    <div class="panel animated">
      <div class="panel-container">
        <form v-on:submit.prevent="onSubmit">
          <div>Completá el formulario y reservá tu mesa ahora.</div>
          <div class="row">
            <div class="col">
              <!-- <input type="text" class="form-control date-input" v-model.trim.lazy="form.date" id="date" placeholder="F. reserva"
              v-on:input="form.date = parseDate($event.target.value)" :min="today" @blur="changeInputTypeToText" @focus="setTodayAndChangeType"/>
              <span for="date" v-for="error in v$.date.$errors" :key="error.$uid">{{ error.$message }}</span> -->
              <VueDatePicker v-model.trim.lazy="form.date" id="date" placeholder="Fecha" 
                auto-apply :min-date="new Date()" prevent-min-max-navigation @blur="v$.date.$touch()"
                :enable-time-picker="false" format="dd/MM/yyyy" :disabled-dates="disabledDates"
                :disabled-week-days="disabledWeekDays"></VueDatePicker>
                <span v-for="error in v$.date.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
            <div class="col">
              <select class="form-select" v-model.trim.lazy="form.time" id="time" @blur="v$.time.$touch()">
                <option :value="null" disabled hidden>Horario</option>
                <option v-for="(h, i) in availableHours" :value="h" v-bind:key="i">{{h}}</option>
              </select>
            </div>
            <div class="col">
              <input @keydown="isNumberKey" class="form-control" v-model.trim.lazy="form.guests" id="guests" placeholder="Personas" @blur="v$.guests.$touch()">
              <span v-for="error in v$.guests.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control" v-model.trim.lazy="form.firstLastName" id="firstLastName" placeholder="Nombre y Apellido" @blur="v$.firstLastName.$touch()">
              <span v-for="error in v$.firstLastName.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
            <div class="col">
              <input type="text" class="form-control date-input" v-model.trim.lazy="form.birthdate" id="birthdate" placeholder="F. nacimiento"
                v-on:input="form.birthdate = parseDate($event.target.value)" min="1950-01-01" :max="today" @blur="changeInputTypeToText" @focus="changeInputTypeToDate"/>
                <span for="birthdate" v-for="error in v$.birthdate.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input type="text" @keydown="isNumberKey" class="form-control" v-model.trim.lazy="form.cellPhone" id="cellPhone" placeholder="Teléfono (Cod. Pais + Cod. Area + Nro. Cel.):  541112345678" @blur="v$.cellPhone.$touch()">
              <span v-for="error in v$.cellPhone.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
            <div class="col">
              <input type="text" class="form-control" id="email" v-model.trim.lazy="form.email" placeholder="Email" @blur="v$.email.$touch()">
              <span v-for="error in v$.email.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control" id="observations" v-model.trim.lazy="form.observations" placeholder="Observaciones">
            </div>
          </div>
          <button type="submit" class="btn" :disabled="v$.$invalid">Reservar</button>
          <div v-if="form.booked">Solo un paso mas...<br> Te enviaremos un mail de confirmación. Por favor validá que tu mail y nro. de celular sean correctos para poder finalizar la reserva.</div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import BookingService from '@/services/dataServices/booking.service';
import horarios from '@/assets/config/horarios';
import moment from 'moment';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from "@vuelidate/validators";
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'BookingPage',
  setup() {
    const today = new Date().toISOString().slice(0, 10);
    const isValidDate = (dateString) => {
      var regEx = /^\d{4}-\d{2}-\d{2}$/;
      if(!dateString.match(regEx)) return false;
      var d = new Date(dateString);
      var dNum = d.getTime();
      if(!dNum && dNum !== 0) return false;
      return d.toISOString().slice(0, 10) === dateString;
    };
    const isValidDateTime = () => {
      let date = new Date(form.date)
      let time = form.time?.split(':')
      date.setHours(time[0])
      date.setMinutes(time[1])
      return new Date().getTime() < date.getTime();
    };
    const isValidEmail = (mail) => {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(mail);
    };
    const $store = useStore();
    const initialState =  ({
      booked: false,
      date: null,
      time: null,
      bookingMinutes: null,
      birthdate: null,
      firstLastName: null,
      guests: null,
      cellPhone: null,
      email: null
    });
    const resetForm = () => {
      form.date = '';
      form.time = '';
      form.guests = '';
      form.firstLastName = ''
      form.birthdate = '';
      form.cellPhone = '';
      form.email = '';
      form.observations = '';
      v$.value.date.$reset();
      v$.value.time.$reset();
      v$.value.guests.$reset();
      v$.value.firstLastName.$reset();
      v$.value.birthdate.$reset();
      v$.value.cellPhone.$reset();
      v$.value.email.$reset();
    };
    var form = reactive({ ...initialState });
    const rules = computed(() => {
      return {
        date: { required: helpers.withMessage("Debe ingresar una fecha", required) },
        time: { required: helpers.withMessage("Debe ingresar un horario", isValidDateTime) },
        firstLastName: { required: helpers.withMessage("Debe ingresar su nombre y apellido", required) },
        guests: { required: helpers.withMessage("Debe ingresar una cantidad de personas", required) },
        birthdate: { required: helpers.withMessage("Debe ingresar una fecha valida de su nacimiento", isValidDate) },
        cellPhone: { required: helpers.withMessage("Debe ingresar su celular (Cod. Pais + Cod. Area + Nro. Cel.)", required) },
        email: { required: helpers.withMessage("Debe ingresar un mail valido", isValidEmail) }
      }
    });
    const disabledDates = [];
    const disabledWeekDays = [];
    const v$ = useVuelidate(rules, form);
    async function onSubmit() {
      const result = await v$.value.$validate();
      const bookForm = ref({
        date: form.date?.toISOString().substring(0, 10),
        time: form.time,
        firstLastName: form.firstLastName,
        guests: form.guests,
        birthdate: form.birthdate,
        cellPhone: form.cellPhone,
        email: form.email,
        observations: form.observations
      });
      if ( result ) {
        const rta = await $store.dispatch("book/createBooking", bookForm.value);
        if ( rta.status == 200 ) {
          form.booked = true;
          // this.reset();
          setTimeout(() => {
            resetForm();
          }, 1000);
        } else {
          form.booked = false;
        }
      }
    }
    return { form, today, v$, onSubmit, disabledDates, disabledWeekDays };
  }, 
  computed: {
    availableHours() {
      let today = new Date();
      let sameDay = false;
      let hoursArray = [];
      let date = this && this.form ? this.parseDate(this.form.date) : '';
      if (date && !isNaN(date.getDay())) {
        let dowSchedule = horarios[date.getDay()],
          i = parseInt(dowSchedule.minTime.hours),
          j = parseInt(dowSchedule.maxTime.hours);
          hoursArray = [];
        if ( today.toLocaleDateString() == date.toLocaleDateString() &&
            ((today.getHours() > i && today.getHours() <= j) || (today.getHours() >= i && today.getHours() < j)) ) {
              i = today.getHours();
              sameDay = true;
        } else if (today.toLocaleDateString() == date.toLocaleDateString() &&
            today.getHours() > i && today.getHours() > j ) {
              i = j;
              sameDay = true;
        }
        
        // sumo 24hs para loopear mas fácil  
        if(i>j) j+=24;

        for(var h = i; h <= j; h++){
          if(h !== j){
            for(var k = 0; k <= dowSchedule.interval.max; k = k + dowSchedule.interval.minutes){
              if (sameDay && ((today.getHours() > h) || (today.getHours() == h && today.getMinutes() >= k))) {} else {
                hoursArray.push(
                  (h < 24 ? h : h - 24).toString().padStart(2,'0') + ':' + k.toString().padStart(2,'0') 
                  )
              }
            }
          } else {
            for(var l = 0; l <= dowSchedule.maxTime.minutes; l = l + dowSchedule.interval.minutes){
              if (sameDay && ((today.getHours() > h) || (today.getHours() == h && today.getMinutes() >= l))) {} else {
                hoursArray.push(
                  (h < 24 ? h : h - 24).toString().padStart(2,'0') + ':' + l.toString().padStart(2,'0') 
                  )
                }
              }
            }
          }
        }
      return hoursArray;
    }
  },
  async mounted(){
    try {
        BookingService.getBlockedDays().then((res) => {
          if ( res ) {
            res.data.forEach(element => {
              this.disabledDates.push(element);
            });
          }
          horarios.forEach((day, idx) => {
            if ( !day.open ) {
              this.disabledWeekDays.push(idx)
            } 
          });
          // console.log(this.disabledDates)
      }).catch((e) => {
        console.error('An error occurred:', e);
      });
    } catch (e) {
      console.error('An error occurred:', e);
    }
  },
  methods: {
    parseDate(date) {
      return moment(date).toDate();
    },
    isNumberKey(evt) {
      if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|ArrowUp|ArrowDown/.test(evt.key)) {
        event.preventDefault();
      }
    },
    changeInputTypeToDate(e){
      e.target.type = 'date';
    },
    setTodayAndChangeType(e){
      if(!e.target.value){
                e.target.value = new Date().toISOString().slice(0, 10);
        this.form.date = new Date().toISOString().slice(0, 10);
      }
      e.target.type = 'date';
    },
    changeInputTypeToText(e){
      if (!e.target.value) {
        e.target.type = 'text';
      }
    },
  }
}
</script>
<style scoped>

.center {
  align-self: center;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.date-input {
    color: grey;
}
</style>