import httpCommon from '../../api/http-common';

class BookingDataService {
    async getAll() {
        try {
            return await httpCommon.get(`/bookings`);
        } catch (err) {
            console.log({ err });
        }
    }
    async getAllFromNow() {
        try {
            return await httpCommon.get(`/bookings/from-now`);
        } catch (err) {
            console.log({ err });
        }
    }
    async getBlockedDays() {
        try {
            return await httpCommon.get(`/bookings/blockedDays`);
        } catch (err) {
            console.log({ err });
        }
    }
    async getAllByParams(params) {
        try {
            return await httpCommon.get(`/bookings/actived${ params }`);
        } catch (err) {
            console.log({ err });
        }
    }

    get(id) {
        return httpCommon.get(`/bookings/${ id }`);
    }

    async create(data) {
        try {
            return await httpCommon.post(`/bookings`, data);
        } catch (err) {
            alert('Error: ' + err.toString());
            console.log({ err });
        }
    }

    async updateBooking(id, data) {
        try {
            console.log({ id });
            return await httpCommon.put(`/bookings/${id}`, data);
        } catch (err) {
            console.log({ err });
        }
    }

    async updateBookingAssisted(id) {
        try {
            return await httpCommon.put(`/bookings/assisted/${id}`);
        } catch (err) {
            console.log({ err });
        }
    }

    async updateBookingValidated(id) {
        try {
            return await httpCommon.put(`/bookings/validated/${id}`);
        } catch (err) {
            console.log({ err });
        }
    }

    async updateBookingConfirmated(id) {
        try {
            return await httpCommon.put(`/bookings/confirmated/${id}`);
        } catch (err) {
            console.log({ err });
        }
    }

    async updateBookingAbsence(id) {
        try {
            return await httpCommon.put(`/bookings/absence/${id}`);
        } catch (err) {
            console.log({ err });
        }
    }

    async cancelBooking(id) {
        try {
            return await httpCommon.put(`/bookings/cancel/${id}`);
        } catch (err) {
            console.log({ err });
        }
    }

    async deleteBooking(id) {
        try {
            console.log({ id });
            return await httpCommon.delete(`/bookings/${id}`);
        } catch (err) {
            console.log({ err });
        }
    }

    deleteAll() {
        return httpCommon.delete(`/bookings`);
    }

    findByTitle(date) {
        return httpCommon.get(`/bookings?date=${date}`);
    }
}

export default new BookingDataService();