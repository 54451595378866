import BookingService from './booking.service';

export const book = {
    namespaced: true,
    actions: {
        createBooking({ commit }, book) {
            return BookingService.create(book).then(
                booking => {
                    commit('createBookingSuccess', booking);
                    return Promise.resolve(booking);
                },
                error => {
                    commit('createBookingFailure');
                    return Promise.reject(error);
                }
            );
        },
        getAllByParams({ commit }, params) {
            return BookingService.getAllByParams(params)
                .then(
                    bookings => {
                        if (bookings) {
                            commit('getAllByParamsSuccess', bookings.data);
                            return Promise.resolve(bookings);
                        }
                        return null;
                    },
                    error => {
                        commit('getAllByParamsFailure');
                        return Promise.reject(error);
                    }
                );
        },
        getAllFromNow({ commit }) {
            return BookingService.getAllFromNow()
                .then(
                    bookings => {
                        commit('getAllFromNowSuccess', bookings.data);
                        return Promise.resolve(bookings);
                    },
                    error => {
                        commit('getAllFromNowFailure');
                        return Promise.reject(error);
                    }
                );
        },
        getBlockedDays({ commit }) {
            return BookingService.getBlockedDays()
                .then(
                    bookings => {
                        commit('getBlockedDaysSuccess', bookings.data);
                        return Promise.resolve(bookings);
                    },
                    error => {
                        commit('getBlockedDaysFailure');
                        return Promise.reject(error);
                    }
                );
        },
        getAll({ commit }) {
            return BookingService.getAll()
                .then(
                    bookings => {
                        commit('getAllSuccess', bookings.data);
                        return Promise.resolve(bookings);
                    },
                    error => {
                        commit('getAllFailure');
                        return Promise.reject(error);
                    }
                );
        },
        updateBooking({ commit }, book) {
            return BookingService.updateBooking(book.id, book)
                .then(
                    rta => {
                        commit('updateBookingSuccess', book, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('updateBookingFailure');
                        return Promise.reject(error);
                    }
                );
        },
        updateBookingAssisted({ commit }, book) {
            return BookingService.updateBookingAssisted(book.id)
                .then(
                    rta => {
                        commit('updateBookingAssistedSuccess', book, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('updateBookingAssistedFailure');
                        return Promise.reject(error);
                    }
                );
        },
        updateBookingConfirmated({ commit }, book) {
            return BookingService.updateBookingConfirmated(book.id)
                .then(
                    rta => {
                        commit('updateBookingConfirmatedSuccess', book, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('updateBookingConfirmatedFailure');
                        return Promise.reject(error);
                    }
                );
        },
        updateBookingAbsence({ commit }, book) {
            return BookingService.updateBookingAbsence(book.id)
                .then(
                    rta => {
                        commit('updateBookingAbsenceSuccess', book, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('updateBookingAbsenceFailure');
                        return Promise.reject(error);
                    }
                );
        },
        updateBookingValidated({ commit }, book) {
            return BookingService.updateBookingValidated(book.id)
                .then(
                    rta => {
                        commit('updateBookingValidatedSuccess', book, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('updateBookingValidatedFailure');
                        return Promise.reject(error);
                    }
                );
        },
        cancelBooking({ commit }, book) {
            return BookingService.cancelBooking(book.id, book)
                .then(
                    rta => {
                        commit('cancelBookingSuccess', book, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('cancelBookingFailure');
                        return Promise.reject(error);
                    }
                );
        },
        deleteBooking({ commit }, book) {
            return BookingService.deleteBooking(book.id, book)
                .then(
                    rta => {
                        commit('deleteBookingSuccess', book, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('deleteBookingFailure');
                        return Promise.reject(error);
                    }
                );
        },
    },
    mutations: {
        createBookingSuccess(state, book) {
            state.book = book;
        },
        createBookingFailure(state, book) {
            state.book = book;
        },
        getAllSuccess(state, book) {
            state.book = book;
        },
        getAllFailure(state, book) {
            state.book = book;
        },
        getAllByParamsSuccess(state, book) {
            state.book = book;
        },
        getAllByParamsFailure(state, book) {
            state.book = book;
        },
        getAllFromNowSuccess(state, book) {
            state.book = book;
        },
        getAllFromNowFailure(state, book) {
            state.book = book;
        },
        async updateBookingSuccess(state, book) {
            await state.book.forEach(bk => {
                if (bk.id === book.id) {
                    bk.date = book.date;
                    bk.time = book.time;
                    bk.guests = book.guests;
                    bk.observations = book.observations;
                    bk.bookingStatusId = book.bookingStatusId;
                }
            });
        },
        async updateBookingAssistedSuccess(state, book) {
            await state.book.forEach(bk => {
                if (bk.id === book.id) {
                    bk = book;
                }
            });
        },
        updateBookingFailure(state, book) {},
        updateBookingAssistedFailure(state, book) {},
        updateBookingValidatedSuccess(state, book) {},
        updateBookingValidatedFailure(state, book) {},
        updateBookingConfirmatedSuccess(state, book) {},
        updateBookingConfirmatedFailure(state, book) {},
        updateBookingAbsenceSuccess(state, book) {},
        updateBookingAbsenceFailure(state, book) {},
        cancelBookingSuccess(state, book) {},
        cancelBookingFailure(state, book) {},
        deleteBookingSuccess(state, book) {},
        deleteBookingFailure(state, book) {},
    },
    getters: {
        loadBookings(state) {
            return state.book;
        }
    }
};