<template>
    <div class="modal" v-if="visible">
      <table id="u_body" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;width:100%" cellpadding="0" cellspacing="0">
        <tbody>
            <tr style="vertical-align: top">
                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                    <div class="u-row-container" style="padding: 0px;background-color: transparent">
                        <div class="u-row" style="height: 47vh;margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-repeat: no-repeat;background-position: center top;background-color: transparent;">
                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                                    <div style="height: 100%;width: 100% !important;-moz-border-radius: 0px;background: radial-gradient(at center bottom, #000000, #363636);border-radius: 2vh;border: 3px solid #33ed83b5;">
                                        <div style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                          <table id="u_content_image_1" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                              <tr>
                                                <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:10px;" align="left">
                                                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                      <tbody>
                                                        <tr>
                                                          <td class="v-text-align" style="padding: 15px;display: flex;justify-content: center;align-items: center;" align="center">
                                                            <img align="center" border="0" src="/api/static/images/Logo.svg" alt="Logo" title="Logo" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;border: none;height: auto;float: none;width: 13vh;max-width: 272.6px;"
                                                                width="272.6" class="v-src-width v-src-max-width">
                                                            <div style="color: white;font-size: 6vh;margin-left: 2vw;">COBRA BAR</div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0" style=" height: 5vh;">
                                              <tbody>
                                                <tr>
                                                  <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:30px 10px 10px;" align="left">
                                                    <div class="v-text-align" style="font-size: 14px; line-height: 170%; text-align: center; word-wrap: break-word;">
                                                      <p style="font-size: 14px; line-height: 170%;"><span style="font-size: 20px; line-height: 34px;"><strong><span style="line-height: 34px; font-size: 20px;">Hola {{ dataClient.name }},</span></strong>
                                                          </span>
                                                      </p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table id="u_content_text_3" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0" style="height: 5vh;">
                                              <tbody>
                                                <tr>
                                                  <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:10px;" align="left">
                                                    <div class="v-text-align" style="font-size: 14px; line-height: 170%; text-align: center; word-wrap: break-word;">
                                                        <p style="font-size: 14px; line-height: 170%;"><span style="font-size: 16px; line-height: 27.2px;">Tu mail {{ dataClient.mail }} ha sido validado.</span>
                                                            <br><br><span style="font-size: 16px; line-height: 27.2px;"></span>
                                                        </p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table id="u_content_button_1" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0" style="height: 6vh;">
                                                <tbody>
                                                    <tr>
                                                        <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:2px;" align="left">

                                                            <div class="v-text-align" align="center" style="padding:10px;">
                                                                <button @click="closeModal" class="v-button v-size-width" style="box-sizing: border-box;display: inline-block;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #FFFFFF; background-color: #33ed83b5; border-radius: 2vh;-webkit-border-radius: 4px; -moz-border-radius: 4px; width:50%; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;font-size: 14px;">
                                                                    <span style="display:block;padding:10px;line-height:120%;"><span style="font-size: 16px; line-height: 19.2px;"><strong><span style="line-height: 19.2px; font-size: 16px;">Cerrar</span></strong>
                                                                    </span>
                                                                    </span>
                                                                </button>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
  </div>
</template>
  
<script>
export default {
  name: 'ActModal',
  props: {
    dataClient: Object,
    visible: Boolean
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 620px) {
            .u-row {
                width: 600px !important;
            }
            .u-row .u-col {
                vertical-align: top;
            }
            .u-row .u-col-50 {
                width: 300px !important;
            }
            .u-row .u-col-100 {
                width: 600px !important;
            }
        }
        
        @media (max-width: 620px) {
            .u-row-container {
                max-width: 100% !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
            .u-row .u-col {
                min-width: 320px !important;
                max-width: 100% !important;
                display: block !important;
            }
            .u-row {
                width: 100% !important;
            }
            .u-col {
                width: 100% !important;
            }
            .u-col>div {
                margin: 0 auto;
            }
        }
        
        body {
            margin: 0;
            padding: 0;
        }
        
        table,
        tr,
        td {
            vertical-align: top;
            border-collapse: collapse;
        }
        
        p {
            margin: 0;
        }
        
        .ie-container table,
        .mso-container table {
            table-layout: fixed;
        }
        
        * {
            line-height: inherit;
        }
        
        a[x-apple-data-detectors='true'] {
            color: inherit !important;
            text-decoration: none !important;
        }
        
        table,
        td {
            color: #fff;
        }
        
        #u_body a {
            color: #0000ee;
            text-decoration: underline;
        }
        
        #u_content_text_4 a {
            color: #f1c40f;
        }
        
        @media (max-width: 480px) {
            #u_content_image_1 .v-src-width {
                width: auto !important;
            }
            #u_content_image_1 .v-src-max-width {
                max-width: 25% !important;
            }
            #u_content_text_3 .v-container-padding-padding {
                padding: 10px 20px 20px !important;
            }
            #u_content_button_1 .v-size-width {
                width: 65% !important;
            }
            #u_content_text_2 .v-container-padding-padding {
                padding: 20px 20px 60px !important;
            }
            #u_content_text_4 .v-container-padding-padding {
                padding: 60px 20px !important;
            }
            #u_content_heading_2 .v-container-padding-padding {
                padding: 30px 10px 0px !important;
            }
            #u_content_heading_2 .v-text-align {
                text-align: center !important;
            }
            #u_content_social_1 .v-container-padding-padding {
                padding: 10px 10px 10px 98px !important;
            }
            #u_content_text_5 .v-container-padding-padding {
                padding: 10px 20px 30px !important;
            }
            #u_content_text_5 .v-text-align {
                text-align: center !important;
            }
        }
</style>
  