import CorporateService from './corporate.service';

export const corp = {
    namespaced: true,
    actions: {
        createCorporate({ commit }, corp) {
            return CorporateService.create(corp).then(
                corpo => {
                    commit('createCorporateSuccess', corpo);
                    return Promise.resolve(corpo);
                },
                error => {
                    commit('createCorporateFailure');
                    return Promise.reject(error);
                }
            );
        },
        getAll({ commit }) {
            return CorporateService.getAll()
                .then(
                    corpo => {
                        commit('getAllSuccess', corpo.data);
                        return Promise.resolve(corpo);
                    },
                    error => {
                        commit('getAllFailure');
                        return Promise.reject(error);
                    }
                );
        },
    },
    mutations: {
        createCorporateSuccess(state, corp) {
            state.corporate = corp;
        },
        createCorporateFailure(state, corp) {
            state.corporate = corp;
        },
        getAllSuccess(state, corp) {
            state.corporate = corp;
        },
        getAllFailure(state, corp) {
            state.corporate = corp;
        },
    },
    getters: {
        loadCorporates(state) {
            return state.corporate;
        }
    }
};