<template>
  <section id="galeria" class="gallery">
    <div id="images-carousel" class="carousel slide carousel-fade" data-mdb-ride="carousel">
                    <button @click="prevGroup" style="left:0" v-html="'<'"></button>

        <div class="carousel-indicators">
            <button v-for="(image,index) in imagesGroups[this.activeGroup]" type="button" data-bs-target="#images-carousel" :data-bs-slide-to="index" :class="index == 0 ? 'active' :''"></button>
        </div>
        <div class="carousel-inner mb-0">
            <div v-for="(image,index) in imagesGroups[this.activeGroup]" :class="index == 0? 'carousel-item active h100':'carousel-item h100'">
                <img :src="image.media_url" class="d-block">
                <div class="mobile opacity-hover">
                    <a :href="imagesGroups[this.activeGroup][0].permalink" class="caption" v-html="imagesGroups[this.activeGroup][0].caption" target="_blank">
                            </a>
                </div>
            </div>
        </div>
         <button  @click="nextGroup" style="right:1px">></button>
    </div>
    <div class="thumbnail desktop">
        <div class="main-gallery">
            <span id="prev" @mouseenter="startScrolling" @mouseleave="stopScrolling" v-html="'<'">
            </span>
            <div class="gallery-container" ref="gallery-container">
                <div class="gallery-gallery">
                    <div v-for="(group,index) in imagesGroups" class="image overflow">
                        <a @click="activeGroup=index">
                            <img :src="group[0].media_url">
                        </a>
                        <div class="opacity-hover">
                            <a :href="group[0].permalink" class="caption" v-html="group[0].caption" target="_blank">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <span id="next" @mouseenter="startScrolling" @mouseleave="stopScrolling" style="right: 0">
            ></span>
        </div>
    </div>
  </section>
</template>
<script>
import GalleryService from '@/services/dataServices/gallery.service';
import { toDisplayString } from 'vue';
export default {
  name: 'GalleryPage',
  data() {
    return {
      timer: null,
      activeImage: 0,
      activeGroup: 0,
      imagesGroups: [],
                          startX: 0,
                    endX: 0
    };
  },
  methods: {
        startScrolling(e) {
            this.timer = setInterval(() => {
                if (e.target.id == 'next') {
                    this.$refs['gallery-container'].scrollLeft += 5;
                } else {
                    this.$refs['gallery-container'].scrollLeft -= 5;
                }
            },20);
        },
        stopScrolling() {
            clearInterval(this.timer);
        },
        nextGroup(){
            if(this.activeGroup+1<this.imagesGroups.length){
                this.activeGroup++
            }
        },
        prevGroup(){
            if(this.activeGroup-1>=0){
                this.activeGroup--
            }
        }
  },
  async created() {
    const images = await GalleryService.getPhotos()
    images.forEach(image=>{
               if(image.children?.data){
            this.imagesGroups.push(image.children?.data)
        } else {
            this.imagesGroups.push([image])
        } 
    })
  }
};
</script>