<template>
      <section class="home" id="inicio">
        <div class="logo"><img src="@/assets/icons/Logo.svg"></div>
        <div class="booking-button"><a href=".reservas" class="btn btn-lg">Reservá online</a></div>
      </section>
      <ActivationModal :visible="modalActVisible" :dataClient="bodyResponse"  @close-modal="closeModal"/>
</template>
<script>
import ActivationModal from './snippets/ActivationModal.vue'
export default {
  name: 'HomePage',
  components: {
    ActivationModal,
  },
  data() {
    return {
      modalActVisible: false,
      bodyResponse: {}
    }
  },
  async mounted() {
    // Smooth scroll function
    const scrollToElement = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Handle clicks on navbar links
    const navLinks = document.querySelectorAll('.booking-button > .btn');
    navLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const targetId = e.target.getAttribute('href').substring(1); // Remove the leading #
        scrollToElement(targetId);
      });
    });

    // this.$route.query.token ? this.modalActVisible = true : null;
    if ( this.$route.query.token ) {
      let token = this.$route.query.token;
      await this.$store.dispatch(`client/verifyMail`, token)
        .then(rta => {
          if ( rta.data && rta.status == 200 ) {
            this.modalActVisible = true;
            this.bodyResponse.message = rta.data.message;
            this.bodyResponse.name = rta.data.name;
            this.bodyResponse.mail = rta.data.mail;
            this.bodyResponse.id = rta.data.id;
          }
        })
        .catch(err => {
          console.log({err})
          this.modalActVisible = false;
          this.$router.push("/");
        });
    }
  },
  methods: {
    closeModal() {
      this.modalActVisible = false;
      this.$router.push("/");
    }
  },
}
</script>