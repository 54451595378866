import httpCommon from '../../api/http-common';

class ClientDataService {

    async verifyMail(token) {
        try {
            return await httpCommon.get(`/clients/validated/mail/${ token }`);
        } catch (err) {
            console.log({ err });
        }
    }

    async sendMailValidator(client) {
        try {
            return await httpCommon.post(`/clients/sendMailValidator`, client);
        } catch (err) {
            console.log({ err });
        }
    }

}

export default new ClientDataService();