import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/components/landing/LandingPage';


const routes = [{
        path: '/',
        name: 'Home',
        component: LandingPage
    },
    {
        path: '/login',
        name: 'Login',
        component: () => /* webpackChunkName: "group-user" */
            import ('@/components/admin/LoginPage')
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => /* webpackChunkName: "group-user" */
            import ('@/components/admin/AdminPage'),
        children: [
            // {
            //     path: 'reservas',
            //     name: 'Reservas',
            //     component: () =>
            //         import ('@/components/admin/content/BookingPage')
            // },
            {
                path: 'menu',
                name: 'Menu',
                component: () =>
                    import ('@/components/admin/content/MenuPage')
            },
            // {
            //     path: 'users',
            //     name: 'Usuarios',
            //     component: () =>
            //         import ('@/components/admin/content/UserPageAdmin')
            // },
        ]
    },
];

const router = createRouter({
    base: '/',
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const authRequired = (to.fullPath).includes("admin");
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;