const optionsCorporate = [{
        category: 'Gastronomia',
        form: 'gastronomia',
        options: [{
                description: 'Bandejeo Simple',
            },
            {
                description: 'Bandejeo Premium',
            },
            {
                description: 'A la carta',
            },
        ],
    },
    {
        category: 'Bebida',
        form: 'bebida',
        options: [{
                description: 'Barra libre',
            },
            {
                description: '"X" tragos por persona (Indicar cantidad en Observaciones)',
            },
        ],
    },
    {
        category: 'Medio de Pago',
        form: 'medioDePago',
        options: [{
                description: 'Factura A',
            },
            {
                description: 'Efectivo',
            },
            {
                description: 'Transferencia',
            },
            {
                description: 'Otro (Indicar en Observaciones)',
            },
        ],
    },
];

export default optionsCorporate;