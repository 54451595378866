import httpCommon from '../../api/http-common';

class BookingStatusDataService {
    async getAll() {
        try {
            return await httpCommon.get(`/booking-status/actived`);
        } catch (err) {
            console.log({ err });
        }
    }
    get(id) {
        return httpCommon.get(`/booking-status/${ id }`);
    }
}

export default new BookingStatusDataService();