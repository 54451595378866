const horarios = [{
        day: 'sunday',
        open: true,
        minTime: {
            hours: '18',
            minutes: '00'
        },
        maxTime: {
            hours: '23',
            minutes: '30'
        },
        interval: {
            minutes: 30, // 15
            max: 30 // 45
        }
    },
    {
        day: 'monday',
        open: false,
        minTime: {
            hours: '',
            minutes: ''
        },
        maxTime: {
            hours: '',
            minutes: ''
        },
        interval: {
            minutes: 0,
            max: 0
        }
    },
    {
        day: 'tuesday',
        open: true,
        minTime: {
            hours: '18',
            minutes: '00'
        },
        maxTime: {
            hours: '23',
            minutes: '30'
        },
        interval: {
            minutes: 30,
            max: 30
        }
    },
    {
        day: 'wednesday',
        open: true,
        minTime: {
            hours: '18',
            minutes: '00'
        },
        maxTime: {
            hours: '23',
            minutes: '30'
        },
        interval: {
            minutes: 30,
            max: 30
        }
    },
    {
        day: 'thursday',
        open: true,
        minTime: {
            hours: '18',
            minutes: '00'
        },
        maxTime: {
            hours: '23',
            minutes: '30'
        },
        interval: {
            minutes: 30,
            max: 30
        }
    },
    {
        day: 'friday',
        open: true,
        minTime: {
            hours: '18',
            minutes: '00'
        },
        maxTime: {
            hours: '23',
            minutes: '30'
        },
        interval: {
            minutes: 30,
            max: 30
        }
    },
    {
        day: 'saturday',
        open: true,
        minTime: {
            hours: '18',
            minutes: '00'
        },
        maxTime: {
            hours: '23',
            minutes: '30'
        },
        interval: {
            minutes: 30,
            max: 30
        }
    }
];

export default horarios;