import { createApp } from 'vue';
import { createStore } from 'vuex';
import { auth } from "./services/auth.module";
import { client } from "./services/dataServices/client.module";
import { book } from "./services/dataServices/booking.module";
import { corp } from "./services/dataServices/corporate.module";
import { bookingStatus } from "./services/dataServices/booking-status.module";
import setupInterceptor from './api/interceptor';
import router from './router';
import App from './App.vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueDatePicker from '@vuepic/vue-datepicker';
import { vMaska } from 'maska';

// Vuetify
// import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import VueCookies from 'vue-cookies';

import '@/assets/bootstrap/bootstrap.min.css';
import '@/assets/bootstrap/bootstrap.bundle.min.js';

import '@/assets/scss/admin.scss';
import '@/assets/scss/landing.scss';
import '@/assets/scss/snippets.scss';
import '@/assets/bootstrap/vue-datepicker.css';




const app = createApp(App);

const store = createStore({
    modules: {
        auth,
        book,
        client,
        corp,
        bookingStatus
    }
});

setupInterceptor(store);
app.use(store);


app.use(router);

app.use(VueCookies, {});

app.component('VueDatePicker', VueDatePicker);
app.component('EasyDataTable', Vue3EasyDataTable);

const vuetify = createVuetify({
    components,
    directives,
});

app.use(vuetify);
// app.config.compilerOptions.isCustomElement = (tag) => tag.includes('-')

app.directive("maska", vMaska);

app.use(VueGoogleMaps, {
    load: {
        key: '',
    },
}).mount('#app');