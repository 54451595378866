//https://graph.instagram.com/me/media?fields=media_url&access_token=IGQWRNT2VLQzdIWHpFcmxrSTZApSEtkRjZAGY0xNQXBDTkRWNXNobkIweW9DVDg3bXlwc1VQQ21aejdTTlEwd3lPcDFlWmU1VFk3NWpzLWp5bF9mbkdzV293d2sxckxTbWxaQUVkYUNXQlBtR3JSRk90d3FxMnlLcTAZD
import axios from 'axios';

const INSTAGRAM_API = process.env.VUE_APP_INSTAGRAM_API;
const API_URL = process.env.VUE_APP_API_URL;

class GalleryService {
  async getPhotos() {
    let access_token = '';
    let images = [];

    await axios.get(API_URL + '/instagram/active')
    .then(res => {
      access_token = res.data.token;
    })
    .catch(err => {
      console.log(err)
    });

    await axios.get(INSTAGRAM_API, {
        params: {
          fields: 'thumbnail_url,media_url,caption,permalink,children{media_url}',
          access_token: access_token
        }
    }).then(response => {
      images =  response.data.data;
    })
    .catch(error => {
      console.log(error)
      images = []
    });
    return images;
  }
}


export default new GalleryService(); 