<template>
  <section id="corporativos" class="booking">
    <div class="panel animated">
      <div class="panel-container">
        <form v-on:submit.prevent="onSubmit">
          <div v-if="!form.done">Dejanos tu información y nos contactaremos a la brevedad</div>
          <div class="row">
            <div class="col">
              <VueDatePicker v-model.trim.lazy="form.dateFrom" id="dateFrom" placeholder="Fecha" 
                auto-apply :min-date="new Date()" prevent-min-max-navigation @blur="v$.dateFrom.$touch()"
                :enable-time-picker="false" format="dd/MM/yyyy"></VueDatePicker>
                <span v-for="error in v$.dateFrom.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
            <div class="col">
              <select class="form-select" v-model.trim.lazy="form.timeFrom" id="timeFrom" @blur="v$.timeFrom.$touch()">
                <option :value="null" disabled hidden>Hora Inicio</option>
                <option v-for="(h, i) in availableHours" :value="h" v-bind:key="i">{{h}}</option>
              </select>
                <span v-for="error in v$.timeFrom.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
            <div class="col">
              <select class="form-select" v-model.trim.lazy="form.timeTo" id="timeTo" @blur="v$.timeTo.$touch()">
                <option :value="null" disabled hidden>Hora Fin</option>
                <option v-for="(h, i) in availableHoursTo" :value="h" v-bind:key="i">{{h}}</option>
              </select>
                <span v-for="error in v$.timeTo.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
            <div class="col">
              <input @keydown="isNumberKey" class="form-control" v-model.trim.lazy="form.guests" id="guests" placeholder="Personas" @blur="v$.guests.$touch()">
              <span v-for="error in v$.guests.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control" v-model.trim.lazy="form.firstName" id="firstName" placeholder="Nombre/Empresa" @blur="v$.firstName.$touch()">
              <span v-for="error in v$.guests.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
            <div class="col">
              <input type="text" @keydown="isNumberKey" class="form-control" v-model.trim.lazy="form.cellPhone" id="cellPhone" placeholder="Teléfono" @blur="v$.cellPhone.$touch()">
              <span v-for="error in v$.cellPhone.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
            <div class="col">
              <input type="text" class="form-control" id="email" v-model.trim.lazy="form.email" placeholder="Email" @blur="v$.email.$touch()">
              <span v-for="error in v$.email.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col" v-for="(option, i) in optionsCorporate" :key="i">
              <!-- <label>{{ option.category }}</label> -->
              <select class="form-select" v-model="selectedOptions[i]">
                <!-- <option :value="null" disabled selected>{{option.category}}</option> -->
                <option :value="undefined" disabled hidden>{{ option.category }}</option>
                <option v-for="(h, j) in option.options" :value="h.description" v-bind:key="j">{{h.description}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <textarea type="text" class="form-control" rows="2" id="observations" v-model.trim.lazy="form.observations" :placeholder="obsPlaceHolder"
              @blur="v$.observations.$touch()"></textarea>
              <span v-for="error in v$.observations.$errors" :key="error.$uid">{{ error.$message }}</span>
            </div>
          </div>
          <button type="submit" class=" p-1 btn" :disabled="v$.$invalid">Reservar</button>
          <div v-if="form.done">Muchas gracias {{ form.firstName }}! <br> A la brevedad nos estaremos contactando.</div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from "@vuelidate/validators";
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import optionsCorporate from '@/assets/config/optionsCorporate';

export default {
  name: 'CorpPage',
  data() {
    const obsPlaceHolder = `Observaciones. Ejemplos: necesito poner un proyector, 15 tragos x persona, pagaria 50% por transferencia y el resto de contado.`;
    const isValidEmail = (mail) => {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(mail);
    };
    const getDataOptions = (option) => {
      for ( let i = 0; i < optionsCorporate.length; i++ ) {
        if ( selectedOptions[i] && optionsCorporate[i].form == option ) {
          return selectedOptions[i];
        }
      }
    };
    const getDateTo = (date, timeFrom, timeTo) => {
      let hhMMFrom = timeFrom.split(':');
      let hhMMTo = timeTo.split(':');
      if ( parseInt(hhMMFrom[0]) > parseInt(hhMMTo[0]) ) {
        let newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate?.toISOString().substring(0, 10) + 'T' + timeTo + ':00.000Z'
      }
      return date?.toISOString().substring(0, 10) + 'T' + timeTo + ':00.000Z'
    };
    const $store = useStore();
    const initialState =  ({
      done: false,
      dateFrom: null,
      timeFrom: null,
      timeTo: null,
      guests: null,
      firstName: null,
      cellPhone: null,
      email: null,
      observations: null,
      minTime: {
        hours:'12',
        minutes: '00'
      },
      maxTime: {
        hours:'00',
        minutes: '00'
      },
      gastronomia: null,
      bebida: null,
      medioDePago: null
    });
    const resetForm = () => {
      form.dateFrom = '';
      form.timeFrom = '';
      form.timeTo = '';
      form.guests = '';
      form.firstName = ''
      form.cellPhone = '';
      form.email = '';
      form.observations = '';
      form.gastronomia = '';
      form.bebida = '';
      form.medioDePago = '';
      v$.value.dateFrom.$reset();
      v$.value.timeFrom.$reset();
      v$.value.timeTo.$reset();
      v$.value.guests.$reset();
      v$.value.firstName.$reset();
      v$.value.cellPhone.$reset();
      v$.value.email.$reset();
      v$.value.observations.$reset();
    };
    var selectedOptions = [];
    var form = reactive({ ...initialState });
    const rules = computed(() => {
      return {
        dateFrom: { required: helpers.withMessage("Debe ingresar una fecha", required) },
        timeFrom: { required: helpers.withMessage("Debe ingresar una hora de Inicio", required) },
        timeTo: { required: helpers.withMessage("Debe ingresar una hora de Finalización", required) },
        firstName: { required: helpers.withMessage("Debe ingresar un nombre", required) },
        guests: { required: helpers.withMessage("Debe ingresar una cantidad de personas", required) },
        cellPhone: { required: helpers.withMessage("Debe ingresar su celular", required) },
        email: { required: helpers.withMessage("Debe ingresar un mail valido", isValidEmail) },
        observations: { required: helpers.withMessage("Debe ingresar sus comentarios a tener en cuenta", required) },
      }
    });
    const v$ = useVuelidate(rules, form);
    async function onSubmit() {
      const result = await v$.value.$validate();
      const corpForm = ref({
        dateFrom: form.dateFrom?.toISOString().substring(0, 10) + 'T' + form.timeFrom + ':00.000Z',
        timeFrom: form.timeFrom,
        dateTo: getDateTo(form.dateFrom, form.timeFrom, form.timeTo),
        timeTo: form.timeTo,
        firstName: form.firstName,
        guests: form.guests,
        cellPhone: form.cellPhone,
        email: form.email,
        observations: form.observations,
        gastronomia: getDataOptions('gastronomia'),
        bebida: getDataOptions('bebida'),
        medioDePago: getDataOptions('medioDePago'),
      });
      if ( result ) {
        const rta = await $store.dispatch("corp/createCorporate", corpForm.value);
        if ( rta.status == 200 ) {
          form.done = true;
          setTimeout(() => {
            resetForm();
            selectedOptions = [];
          }, 1000);
        } else {
          form.done = false;
        }
      }
    }
    return { obsPlaceHolder, form, selectedOptions, optionsCorporate, v$, onSubmit };
  },
  computed: {
    availableHours() {
      let today = new Date();
      let sameDay = false;
      let hoursArray = [];
      if ( this && this.form.minTime != null && this.form.maxTime != null ) {
        let i = parseInt(this.form.minTime.hours),
        j = parseInt(this.form.maxTime.hours);
        hoursArray = [];
        if ( new Date(new Date(today).toDateString()).getTime() == new Date(new Date(this.form.dateFrom).toDateString()).getTime() &&
          today.getHours() > i && today.getHours() <= j ) {
            i = today.getHours();
            sameDay = true;
        } else if ( new Date(new Date(today).toDateString()).getTime() == new Date(new Date(this.form.dateFrom).toDateString()).getTime() &&
          today.getHours() > i && today.getHours() > j ) {
            i = j;
            sameDay = true;
        }

        // sumo 24hs para loopear mas fácil  
        if(i>j) j+=24;

        for(var h = i; h < j; h++){
          if(h !== j){
            for(var k = 0; k <= 45; k = k + 15){
              if (sameDay && ((today.getHours() > h) || (today.getHours() == h && today.getMinutes() >= k))) {} else {
                hoursArray.push(
                  (h < 24 ? h : h - 24).toString().padStart(2,'0') + ':' + k.toString().padStart(2,'0') 
                )
              }
            }
          } else {
            for(var l = 0; l <= this.form.maxTime.minutes; l = l + 15){
              if (sameDay && ((today.getHours() > h) || (today.getHours() == h && today.getMinutes() >= l))) {} else {
                hoursArray.push(
                  (h < 24 ? h : h - 24).toString().padStart(2,'0') + ':' + l.toString().padStart(2,'0') 
                )
              }
            }
          }
          
        }
      }
      return hoursArray;
    },
    availableHoursTo() {
      if ( this.form.timeFrom ) {
        let time = this.form.timeFrom.split(':');
        let hoursArray = [];
        let nextDay = 0;
        for (let i = 1; i < 12; i++) {
          let hr = parseInt(time[0]) + i;
          if ( hr >= 24 ) {
            hr = nextDay.toString();
            hr.length == 1 ? hr = `0${ nextDay.toString() }` : hr;
            nextDay++;
          }
          hoursArray.push(`${ hr }:${ time[1] }`);
        }
        return hoursArray;
      }
    },
  },
  methods: {
    isNumberKey(evt) {
      if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|ArrowUp|ArrowDown/.test(evt.key)) {
        event.preventDefault();
      }
    },
  },
}
</script>