import ClientService from './client.service';

export const client = {
    namespaced: true,
    actions: {
        sendMailValidator({ commit }, client) {
            return ClientService.sendMailValidator(client)
                .then(
                    rta => {
                        commit('sendMailValidatorSuccess', client, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('sendMailValidatorFailure');
                        return Promise.reject(error);
                    }
                );
        },
        verifyMail({ commit }, client) {
            return ClientService.verifyMail(client)
                .then(
                    rta => {
                        commit('verifyMailSuccess', client, rta);
                        return Promise.resolve(rta);
                    },
                    error => {
                        commit('verifyMailFailure');
                        return Promise.reject(error);
                    }
                );
        },
    },
    mutations: {
        sendMailValidatorSuccess(state, client) {},
        sendMailValidatorFailure(state, client) {},
        verifyMailSuccess(state, client) {},
        verifyMailFailure(state, client) {},
    },
    getters: {}
};