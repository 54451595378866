import httpCommon from '../../api/http-common';

class CorporateDataService {
    async getAll() {
        try {
            return await httpCommon.get(`/corporates`);
        } catch (err) {
            console.log({ err });
        }
    }

    async get(id) {
        try {
            return await httpCommon.get(`/corporates/${ id }`);
        } catch (err) {
            alert('Error: ' + err.toString());
            console.log({ err });
        }
    }

    async create(data) {
        try {
            return await httpCommon.post(`/corporates`, data);
        } catch (err) {
            alert('Error: ' + err.toString());
            console.log({ err });
        }
    }
}
export default new CorporateDataService();