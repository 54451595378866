import BookingStatusService from './booking-status.service';

export const bookingStatus = {
    namespaced: true,
    actions: {
        async getAll({ commit }) {
            return await BookingStatusService.getAll()
                .then(
                    bookingStatuses => {
                        if (bookingStatuses) {
                            commit('getAllBookingStatusesSuccess', bookingStatuses.data);
                            return Promise.resolve(bookingStatuses.data);
                        }
                    },
                    error => {
                        commit('getAllBookingStatusesFailure');
                        return Promise.reject(error);
                    }
                );
        },
    },
    mutations: {
        getAllBookingStatusesSuccess(state, bookingStatuses) {
            state.bookingStatuses = bookingStatuses;
        },
        getAllBookingStatusesFailure(state, bookingStatuses) {
            state.bookingStatuses = bookingStatuses;
        },
    },
    getters: {
        loadBookingStatuses(state) {
            return state.bookingStatuses;
        }
    }
};