<template>
    <div class="landing">
      <!-- <Navigator/> -->
      <Navigator  v-if="false"/>
      <div data-bs-spy="scroll">
      <HomePage  v-if="false"/>
      <div class="sharedBackground" >
        <MenuPage ref="animatedComponent"/>
        <BookingPage ref="animatedComponent"  v-if="false"/>
        <div class="stickedContactPages"  v-if="false">
          <CorpPage ref="animatedComponent"/>
          <MapPage ref="animatedComponent"/>
          <ContactBar ref="animatedComponent"  v-if="false"/>
        </div>
        <GalleryPage ref="animatedComponent"  v-if="false"/>
      </div>
      </div>
      <a href="https://www.instagram.com/cobra.ba/?hl=en" target="_blank"><img src="@/assets/icons/instagram_multicolor.svg" class="floatingInstagramLogo"></a>
    </div>
  </template>
  
<script>
  import Navigator from './Navigator.vue'
  import HomePage from './HomePage.vue'
  import MenuPage from './MenuPage.vue'
  import BookingPage from './BookingPage.vue'
  import CorpPage from './CorpPage.vue'
  import ContactBar from './ContactBar.vue'
  import MapPage from './MapPage.vue'
  import GalleryPage from './GalleryPage.vue'
  
  export default {
    name: 'LandingPage',
    components: {
      Navigator,
      HomePage,
      MenuPage,
      BookingPage,
      ContactBar,
      MapPage,
      GalleryPage,
      CorpPage
    },
  mounted() {
    this.observeAnimatedDivs();
  },
  methods: {
    observeAnimatedDivs() {
      const elements = document.querySelectorAll('.animated');

      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animation');
          } else {
            entry.target.classList.remove('animation');
          }
        });
      }, observerOptions);

      elements.forEach((el) => {
        observer.observe(el);
      });
    },
  },
};
</script>

<style>
</style>
  