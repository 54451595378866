<template>
  <nav class="navigator desktop" > 
    <div class="container-fluid">
      <div class="row" id="navbarContent" >
        <ul class="navbar-nav" @click="setActive">
          <li class="nav-item col-sm item">
            <a class="nav-link active" href="#inicio">INICIO</a>
          </li>
          <li class="nav-item col-sm item">
            <a class="nav-link" href="#menu">MENÚ</a>
          </li>
          <li class="nav-item col-sm item">
            <a class="nav-link" href="#reservas">RESERVAS</a>
          </li>
          <li class="nav-item col-sm item">
            <a class="nav-link" href="#corporativos">CORPORATIVOS</a>
          </li>
          <li class="nav-item col-sm item">
            <a class="nav-link" href="#contacto">CONTACTO</a>
          </li>
          <li class="nav-item col-sm item">
            <a class="nav-link" href="#galeria">GALERÍA</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  
  <nav class="navigator mobile">
    <button class="border-0 my-1" type="button" @click="this.active=!this.active">
      <img src="@/assets/icons/CloseButton.svg" v-if="active"/>
      <img src="@/assets/icons/MenuButton.svg" v-else/>
    </button>

    <!-- Mobile menu with transition -->
    <div >
      <div v-if="active" :class="active? 'mobile-menu active': 'mobile-menu'">
        <!-- Menu items -->
        <ul class="navbar-nav" id="navlinks" @click="setActive">
          <li class="nav-item col-sm">
            <a class="nav-link active" href="#inicio">INICIO</a>
          </li>
          <li class="nav-item col-sm">
            <a class="nav-link" href="#menu">MENÚ</a>
          </li>
          <li class="nav-item col-sm">
            <a class="nav-link" href="#reservas" >RESERVAS</a>
          </li>
          <li class="nav-item col-sm">
            <a class="nav-link" href="#corporativos">CORPORATIVOS</a>
          </li>
          <li class="nav-item col-sm">
            <a class="nav-link" href="#contacto">CONTACTO</a>
          </li>
          <li class="nav-item col-sm">
            <a class="nav-link" href="#galeria">GALERÍA</a>
          </li>
          <!-- Add more menu items as needed -->
        </ul>
      </div>
    </div>
  </nav>
</template>
  
  <script>

  export default {
    data(){
      return {
        active: false
      }
    },
    methods:{
      setActive(e){
        e.target.parentNode.parentNode.getElementsByClassName('nav-link active')[0].classList.remove('active');
        
        e.target.classList.add('active');
        this.active = false;
      },
    },

  }
  </script>