<template>
    <div id="contactBar" class="contactBar container-flex row background">
        <div class="col-sm"> 
          <div class="titulo">
            Dirección
          </div>
          <div class="info">
            <div>
              Av. Coronel Marcelino E. Freyre 3880<br>
              Paseo de la Infanta, Arco 15<br>
              Palermo, Buenos Aires<br>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="titulo"> 
            Horarios
          </div>
          <div class="info">
            <div>
              Martes a Jueves de 11:00 a 02:00<br>
              Viernes y Sábados de 11:00 a 04:00<br>
              Domingos de 13:00 a 21:00<br>
            </div>
          </div>
        </div>
        <div class="col-sm"> 
          <div class="titulo">
            Contacto
          </div>
          <div class="info">
            <div>
              cobrabuenosaires@gmail.com<br>
              <a :href="`https://wa.me/${reserva_celular}`" target="_blank"><img src="@/assets/icons/Whatsapp.svg" />{{reserva_celular}}</a><br>
              <a href="https://instagram.com/cobra.ba" target="_blank"><img src="@/assets/icons/Instagram.svg" />instagram.com/cobra.ba</a><br>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'ContactBar',
  data() {
    return {
      backgroundPosition : "0px",
      reserva_celular: process.env.VUE_APP_CELULAR
    }
  },
  methods:{
    changebp () {
      this.backgroundPosition = window.scrollY-window.innerHeight > 0 ? (-window.scrollY+window.innerHeight-(window.innerHeight*0.75)) + "px" : "0vh"
    }
  },
  mounted(){
    window.addEventListener("scroll", this.changebp)
  }
}
</script>
<style>
.background{
    background-position-y: v-bind(backgroundPosition);
}
</style>