<template>
  <section id="contacto" class="location">
    <div class="panel">
      <div class="panel-container">
        <a target="_blank" href="https://www.google.com/maps/place/Cobra/@-34.5704989,-58.4221263,18.23z/data=!4m6!3m5!1s0x95bcb5b39404dffd:0xd617e2d1cc5078b1!8m2!3d-34.5698101!4d-58.4210314!16s%2Fg%2F11nryyz8vs?entry=ttu"></a>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'MapPage',
}
</script>